<template>
  <s-input-number
    :id="`j-checkout-bag__${item.id}-${source}`"
    v-model="quantity"
    class="shopping-bag__item-input"
    pattern="[0-9]*"
    :max="isSheinClubGiftItem ? quantity : 99"
    :min="minNum"
    :disable-inc="disabledAdd || editCartLoading"
    :disable-dec="editCartLoading"
    :disable="disabledInputNumber"
    @focus="focusNumber"
    @blur="blurNumber"
    @change="changeNumber"
    @increase="increase"
    @decrease="decrease"
    @reachToMax="reachToMax"
  />
</template>

<script>
import { InputNumber as SInputNumber } from '@shein/sui-mobile'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import * as api from 'public/src/pages/product_app/store/modules/checkout/api.js'
import {
  handleOrderOrSingleIsMeetOrOver,
} from 'public/src/pages/cartNew/utils/index.js'
import { batchWishGoods } from 'public/src/pages/cartNew/utils/fetcher.js'

daEventCenter.addSubscriber({ modulecode: '1-11-3' })
const { langPath } = gbCommonInfo

export default {
  name: 'InputNumber',
  components: {
    SInputNumber,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
    isPromitionGoods: {
      type: Boolean,
      default: false
    },
    isOutStockGood: {
      type: Boolean,
      default: false
    },
    /**
     * 注意source会互相影响，请区分场景值！！！
     * 由何处触发的商品编辑行为
     * inner 购物袋弹窗
     * outer 购物袋入口
     * largerInform 大件海陆运提示弹窗
     * limitGoodsDialog 限制商品弹窗
     */
    source: {
      type: String,
      default: ''
    },
    isSheinClubGiftItem: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      quantity: 0,
      reachMax: false
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'flashSaleGroupList',
      'underPriceGroupList',
      'discountPriceGroupList',
    ]),
    ...mapState('checkout', [
      'language',
      'checkout',
      'editCartInfo',
      'editCartLoading',
      'integrityPromotionInfo',
      'buyNowNo',
      'shownLimitTipsStatus'
    ]),
    isLargeShipGoods() {
      return this.source == 'largerInform'
    },
    disabledAdd() {
      return this.isOutStockGood || this.isOrderOrSingleMeetOrOver || this.item.quantity >= 99 || this.isLimitProduct || (+this.item.quantity >= +this.item.realTimeInventory) || this.isSheinClubGiftItem
    },
    disabledInputNumber() {
      // 只要是会员赠品且不是无库存，可编辑-号按钮
      if(!this.isOutStockGood && this.isSheinClubGiftItem) {
        return false
      }
      return this.isPromitionGoods || this.isOutStockGood
    },
    minNum() {
      return this.isLastGoods ? (this.isSheinClubGiftItem ? 0 : 1) : 0
    },
    promotionGroupInfo(){
      let groupPromotion = this.item.product.product_promotion_info?.find(promotion =>  [10, 11, 24, 30].includes(promotion.type_id))
      if(groupPromotion) {
        let groupName = [10, 11].includes(groupPromotion.type_id) ? 'flashSaleGroupList' : ([30].includes(groupPromotion.type_id) ? 'underPriceGroupList' : 'discountPriceGroupList')
        let groupPro = this.integrityPromotionInfo[groupPromotion.promotion_id] || {}
        return { ...groupPro, list: this[groupName][groupPromotion.promotion_id] || [] }
      }

      return {}
    },
    // 单品或订单限购满足数量或超过 置灰添加按钮
    isOrderOrSingleMeetOrOver(){
      let result = handleOrderOrSingleIsMeetOrOver(this.item, this.promotionGroupInfo, false)
      return Boolean(result)
    },
    // 限时折扣
    isLimitProduct(){
      let promotion = this.item.product.product_promotion_info?.find(item => item.type_id == 3)
      if(!promotion) return false
      const { promotion_id = '' } = promotion
      const promotionInfo = this.integrityPromotionInfo[promotion_id] || {}
      let { single } = promotionInfo.cart_limit || {}
      return single && this.item.quantity >= single
    },
    isSameItem() {
      let isSame = false
      const isSameItem = this.editCartInfo?.item?.id == this.item?.id

      // 外层购物袋触发，qs库存不足拆商品特殊场景
      if(this.editCartInfo?.quickshipInfo?.type != -1) {
        const isQuickship = this.editCartInfo?.quickshipInfo?.type == 1
        const realSame = (isQuickship && this.item.over_quick_ship != 1) || (!isQuickship && this.item.over_quick_ship == 1)
        isSame = isSameItem && realSame
      } else {
        isSame = isSameItem
      }

      return isSame
    },
  },
  watch: {
    item: {
      handler() {
        this.quantity = this.item.quantity
      },
      immediate: true,
      deep: true,
    },
    'editCartInfo.isCancel': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.quantity = this.editCartInfo?.oldValue
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isCancel: false,
              source: ''
            } 
          })
        }
      }
    },
    'editCartInfo.isQsConfirm': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isQsConfirm: false,
              quickshipInfo: {
                type: -1,
                qsQuantity: 0,
                noQsQuantity: 0
              },
              source: ''
            } 
          })
          this.updateCartQuantity(this.editCartInfo?.currentValue, this.editCartInfo?.oldValue)
        }
      }
    },
    'editCartInfo.isDelete': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isDelete: false,
              source: ''
            } 
          })
          this.deleteCart(this.editCartInfo?.item)
        }
      }
    },
    'editCartInfo.isMoveWhislist': {
      handler(v) {
        if(v && this.isSameItem && this.source == this.editCartInfo.source) {
          this.changeParamsStatus({ 
            type: 'editCartInfo', 
            params: { 
              isMoveWhislist: false,
              source: ''
            } 
          })
          this.moveCartToWhislist(this.editCartInfo?.item)
        }
      }
    },
  },
  mounted() {
    document.querySelector(`#j-checkout-bag__${this.item.id}-${this.source} .S-input-number__decrease`)?.addEventListener('click', this.handleDisableToast)
  },
  beforeDestroy() {
    document.querySelector(`#j-checkout-bag__${this.item.id}-${this.source} .S-input-number__decrease`)?.removeEventListener('click', this.handleDisableToast)
  },
  methods: {
    ...mapMutations('checkout', ['assignState', 'updateState', 'changeParamsStatus']),
    ...mapActions('checkout', ['updateCart']),
    template,
    handleDisableToast() {
      // 存在从2－1场景也会触发提示，增加updateCartLoading判断
      if(this.isLastGoods && this.quantity == 1 && !this.editCartLoading) {
        this.$toast(this.language.SHEIN_KEY_PWA_28660)
      }
    },
    handleLimitTips({ resultInfo, oldQuantity }) {
      const { cart, info } = resultInfo
      const cartInfo = info?.carts?.find(item => cart.id == item.id)
      if (!cartInfo) return
      const { product_promotion_info = [] } = cartInfo?.product || {}
      const showTips = product_promotion_info?.some(promotion => {
        // 目前拦截的都会报错，没有报错的即为超限原价买
        const { member_over, order_over, single_over, stock_over } = promotion
        const isSingleLimit = +member_over === 1 || +order_over === 1
        const isMultipleLimit = +single_over === 1
        const isActivitySingleLimit = +stock_over === 1
        return isSingleLimit || isMultipleLimit || isActivitySingleLimit
      })
      
      if (showTips) {
        if (this.shownLimitTipsStatus[cart.id] || resultInfo.cart.quantity <= oldQuantity) return
        this.shownLimitTipsStatus[cart.id] = true
        this.$toast(this.language.SHEIN_KEY_PWA_23038)
      } else if (this.shownLimitTipsStatus[cart.id]) {
        // 没有触发限购时需要重置状态
        this.shownLimitTipsStatus[cart.id] = false
      }
    },
    handleSuccessPesponse({ isDel = false, id = '' } = {}) {
      this.updateCart({
        noUpdataShipping: 1,
        fn: () => {
          // 删除商品增加动效
          if(isDel && !!id) {
            document.getElementById(`checkout-cart-item-${id}`).style.opacity = 0
          }
        }
      })
      appEventCenter.$emit('update-shipping-method', { address: this.checkout.default_address, modifyCart: true })
    },
    async handleQtyResponse({ res, old_quantity = 0 }) {
      if ( res.code == 0 && res.info ) {
        this.handleLimitTips({ resultInfo: res.info, oldQuantity: old_quantity })
        this.handleSuccessPesponse()
      } else {
        // 接口错误，恢复原来的数量
        this.quantity = old_quantity

        if ( res.code == 300206 ) {
          window.location = langPath + '/user/logout'
        } else if ( res.code == 300402 ) {
          this.$toast(this.language.SHEIN_KEY_PWA_15230)
        } else if ( [ 200401, 500301,  302444, 300714 ].includes(+res.code) ) {
          let errTxt = this.language.SHEIN_KEY_PWA_14967
          if ( res.info && res.info.limitCount ) {
            errTxt = this.template(res.info.limitCount, this.language.SHEIN_KEY_PWA_16138)
          }
          if ( [ 300714, 302444 ].includes(+res.code) ) {
            errTxt = this.template(res?.info?.limitCount, res.tips)
          }
          this.$toast(errTxt)
        } else if ( [500302, 500303, 500306, 300417 ].includes(+res.code) ) { 
          // TODO: 这个语言占位符比较特殊从1 开始的
          this.$toast(this.template('', res.info?.resultLimit, res.info?.remainNum, res.tips))
        } else if ( res.code == 300405 ) {
          this.$toast(res.info.limit_desc.replace('N', res.info.limit_count))
        } else if ( res.code == 300470 ) {
          let errTxt = this.template(res.info?.single_row_capacity_limit, res.tips)
          this.$toast(errTxt)
        } else {
          this.$toast(res.tips)
        }
      }
    },
    updateCartQuantity(currentValue, oldValue) {
      this.assignState({
        editCartLoading: true
      })
      api.updateCartItem({ quantity: currentValue, id: this.item.id, checkout_no: this.buyNowNo })
        .then(res => {
          this.assignState({
            editCartLoading: false
          })
          this.handleQtyResponse({ res, old_quantity: oldValue })
        })
        .catch(err => {
          this.assignState({
            editCartLoading: false
          })
          if (err.code === 'ERR_CANCELED') return
          // 接口错误，恢复原来的数量
          this.quantity = oldValue
        })
    },
    async deleteCart(item) {
      this.assignState({
        editCartLoading: true
      })
      // 立即购场景
      let buyNowNoAgument = {}
      if(this.buyNowNo){
        buyNowNoAgument = {
          buyNowNo: this.buyNowNo, 
          buyNowNoParams: {
            cart_id_list: [this.item.id],
            checkout_no: this.buyNowNo
          }
        }
      }
      api.deleteCartItem({ id: item.id, ...buyNowNoAgument })
        .then(res => {
          this.assignState({
            editCartLoading: false
          })
          if (res.code != 0) {
            // 接口错误，恢复原来的数量
            this.quantity = this.editCartInfo?.oldValue
            this.$toast(this.language.SHEIN_KEY_PWA_28686)
            return
          }

          this.handleSuccessPesponse({ isDel: true, id: item.id })
        })
        .catch(() => {
          this.assignState({
            editCartLoading: false
          })
          // 接口错误，恢复原来的数量
          this.quantity = this.editCartInfo?.oldValue
          this.$toast(this.language.SHEIN_KEY_PWA_28686)
        })
    },
    async moveCartToWhislist(item) {
      this.assignState({
        editCartLoading: true
      })
      const ids = [item?.id]
      const res = await batchWishGoods({ ids, filterCheck: 1 })
      this.assignState({
        editCartLoading: false
      })

      if(res?.code == 0) {
        this.$toast(this.language.SHEIN_KEY_PWA_28544)

        this.handleSuccessPesponse({ isDel: true, id: item.id })
      } else {
        // 接口错误，恢复原来的数量
        this.quantity = this.editCartInfo?.oldValue
        this.$toast(this.language.SHEIN_KEY_PWA_28685)
      }
    },
    sendBiMethod(type, params) {
      const source = {
        'focus': '1-11-3-6',
        'input': '1-11-3-7',
        'increase': '1-11-3-8',
        'decrease': '1-11-3-9'
      }
      const id = source[type]
      daEventCenter.triggerNotice({
        daId: id,
        extraData: {
          ...params
        }
      })
    },
    focusNumber() {
      this.sendBiMethod('focus')
      // 键盘弹出事件处理
      $('body').addClass('checkout-body-disabled')
    },
    blurNumber() {
      // 键盘收起事件处理
      $('body').removeClass('checkout-body-disabled')
    },
    increase() {
      // type: 1, shipping bag; 2、异常提示; 3、海陆运
      const typeInfo = {
        'inner': 1,
        'outer': 1,
        'limitGoodsDialog': 2,
        'largerInform': 3
      }
      this.sendBiMethod('increase', {
        type: typeInfo[this.source] || 1
      })
    },
    decrease() {
      // type: 1, shipping bag; 2、异常提示; 3、海陆运
      const typeInfo = {
        'inner': 1,
        'outer': 1,
        'limitGoodsDialog': 2,
        'largerInform': 3
      }
      this.sendBiMethod('decrease', {
        type: typeInfo[this.source] || 1
      })
    },
    changeNumber(currentValue, oldValue, type) {
      const source = this.source
      if(type == 'input') {
        this.sendBiMethod('input')
      }
      let finalVal = currentValue
      setTimeout(() => {
        this.quantity = parseInt(currentValue)
      }, 0)
      finalVal = parseInt(currentValue)

      // 当输入数量=0，自动变更为1（变为0只能通过减号触发）
      if(type == 'input' && finalVal <= 0) {
        setTimeout(() => {
          this.quantity = 1
        }, 0)
        finalVal = 1
      }
      // 单个商品无法超过99件
      if(type == 'input' && this.reachMax) {
        this.$toast(this.template(99, this.language.SHEIN_KEY_PWA_28828))
        setTimeout(() => {
          this.quantity = oldValue
        }, 0)
        finalVal = oldValue
        this.reachMax = false
        return
      }
      
      const { overQsQuantity, quickshipType, qsQuantity, noQsQuantity, nextVal } = this.handleQuickshipData({ finalVal, oldValue })
      finalVal = nextVal

      // 删除商品触发挽留弹窗（二次校验）（注意qs库存拆分场景如果同个id未全部删除不进行弹窗提醒）
      if(type == 'decrease' && finalVal == 0) {
        if(this.source == 'limitGoodsDialog') {
          this.$emit('del-second-confirm-handle')
        } else {
          this.updateState({
            key: 'showShoppingBagRetain', value: true
          })
        }
        this.changeParamsStatus({ 
          type: 'editCartInfo', 
          params: { 
            item: this.item,
            type: 'retain',
            oldValue,
            source,
            isLargeShipGoods: this.isLargeShipGoods, // 大件海陆运不触发折扣挽留
          } 
        })

        return
      }

      const goods_list = typeof sessionStorage.getItem('edit_quickship_goods') === 'string' ? JSON.parse(sessionStorage.getItem('edit_quickship_goods')) : []
      if(overQsQuantity && !goods_list.includes(this.item.id)) {
        this.updateState({
          key: 'showRecheckQuickship', value: true
        })
        this.setQuickShipShowList(this.item.id)
        this.changeParamsStatus({ 
          type: 'editCartInfo', 
          params: { 
            item: this.item,
            type: 'quickship',
            quickshipInfo: {
              type: quickshipType,
              qsQuantity,
              noQsQuantity
            },
            currentValue: finalVal,
            oldValue,
            source
          } 
        })

        return
      }
      
      if(finalVal != oldValue) this.updateCartQuantity(finalVal, oldValue)
    },
    handleQuickshipData({ finalVal, oldValue }) {
      let overQsQuantity = false
      const isQuickshipCart = this.item?.quick_ship == 1
      let quickshipType = -1
      let qsQuantity = 0
      let noQsQuantity = 0
      let nextVal = finalVal

      // 购物袋入口处的qs由前端拆分，需要特殊处理
      if(isQuickshipCart && this.source == 'outer') {
        quickshipType = this.item.over_quick_ship == 1 ? 0 : 1
        const currItem = this.checkout.results?.carts?.carts?.find(item => item.id == this.item.id) || {}
        const prevQuantity = +currItem.quantity || 0 // 接口调用前的数量
        // -1的场景不需要特殊处理
        qsQuantity = quickshipType == 1 ? oldValue : prevQuantity - oldValue
        noQsQuantity = quickshipType == 0 ? oldValue : prevQuantity - oldValue
        nextVal = quickshipType == 1 ? (finalVal + noQsQuantity) : (finalVal + qsQuantity)

        const qs_stock = +this.item?.product?.qs_stock || 0
        overQsQuantity = nextVal > qs_stock
      }

      return {
        overQsQuantity,
        quickshipType,
        qsQuantity,
        noQsQuantity,
        nextVal
      }
    },
    setQuickShipShowList(id = '') {
      const goods_list = typeof sessionStorage.getItem('edit_quickship_goods') === 'string' ? JSON.parse(sessionStorage.getItem('edit_quickship_goods')) : []
      if(!!id && !goods_list.includes(id)) {
        goods_list.push(id)
        sessionStorage.setItem('edit_quickship_goods', JSON.stringify(goods_list))
      }
    },
    reachToMax() {
      this.reachMax = true
    },
  },
  emits: ['del-second-confirm-handle']
}
</script>

<style lang="less">
.shopping-bag__item-input {
  .S-input-number__decrease {
    width: 44/75rem;
  }
  .S-input-number__increase {
    width: 44/75rem;
  }
  .S-input-number__input {
    width: 60/75rem;
  }
}

.checkout-body-disabled {
  pointer-events: none;
}
</style>
